import React from "react"
import Logo from "./Logo"
import NavItem from "../nav/NavItem"
import NavItemSecondary from "./NavItemSecondary"
import ContactData from "../contact/ContactData"
import karte from "../../assets/images/karte.webp"

const Footer = () => {
  return (
    <footer
      className="bg-cover px-4 py-8 relative text-white sm:py-12 md:py-16 lg:py-20 before:absolute before:bg-brand-primary-light before:content-[''] before:inset-0 before:opacity-80"
      style={{ backgroundImage: `url('${karte}')` }}
    >
      <nav className="grid gap-y-12 max-w-content mx-auto relative md:flex md:items-center md:justify-between md:gap-x-40 lg:gap-x-80">
        <div className="flex-1">
          <Logo />
          <div className="grid gap-y-4 mt-4">
            <ul className="grid gap-4 mt-4">
              <NavItem
                to={"/ueber-uns"}
                title={"Über uns"}
                icon={"building-user"}
              />
              <NavItem
                to={"/geschaeftsfelder"}
                title={"Geschäftsfelder"}
                icon={"briefcase"}
              />
              <NavItem
                to={"/karriere"}
                title={"Karriere"}
                icon={"graduation-cap"}
              />
              <NavItem
                to={"/kontakt"}
                title={"Kontakt"}
                icon={"address-book"}
              />
            </ul>
            <ul className="grid gap-y-2">
              <NavItemSecondary to={"/impressum"} title={"Impressum"} />
              <NavItemSecondary to={"/datenschutz"} title={"Datenschutz"} />
            </ul>
          </div>
        </div>
        <div className="flex-1">
          <ContactData />
        </div>
      </nav>
    </footer>
  )
}

export default Footer
