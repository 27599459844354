import React from "react"
const NavMenuButton = ({ onClick, isNavMenuOpen }) => {
  return (
    <li
      className={`${
        isNavMenuOpen ? "bg-brand-primary-light" : ""
      } flex h-10 items-center justify-center w-10 lg:hidden`}
    >
      <button
        title={isNavMenuOpen ? "Menü schließen" : "Menü öffnen"}
        className="grid gap-y-1 w-6"
        onClick={onClick}
      >
        <div
          className={`bg-white h-0.75 w-full ${
            isNavMenuOpen ? "translate-y-[7px] -rotate-45" : ""
          }`}
        ></div>
        <div
          className={`bg-white h-0.75 w-full ${
            isNavMenuOpen ? "opacity-0" : ""
          }`}
        ></div>
        <div
          className={`bg-white h-0.75 w-full ${
            isNavMenuOpen ? "-translate-y-[7px] rotate-45" : ""
          }`}
        ></div>
      </button>
    </li>
  )
}

export default NavMenuButton
