import React, { useState } from "react"
import NavLogo from "./NavLogo"
import NavMenuButton from "./NavMenuButton"
import NavItem from "../nav/NavItem"

const Header = () => {
  const [isNavMenuOpen, setNavMenuOpen] = useState(false)
  const hideNavMenu = (show = false) => {
    setNavMenuOpen(show)
  }
  return (
    <header className="bg-brand-primary fixed text-white w-full z-10">
      <nav className="h-20 px-4 max-w-content mx-auto lg:flex lg:gap-x-4">
        <ul className="flex h-full items-center justify-between py-6 lg:py-5">
          <NavLogo
            isNavMenuOpen={isNavMenuOpen}
            onClick={() => hideNavMenu()}
          />
          <NavMenuButton
            onClick={() => hideNavMenu(!isNavMenuOpen)}
            isNavMenuOpen={isNavMenuOpen}
          />
        </ul>
        <div
          id="nav-menu"
          className={`bg-brand-primary fixed top-header bottom-0 inset-x-0 p-4 w-screen ${
            isNavMenuOpen ? "block" : "hidden"
          } overflow-auto border-t-2 border-brand-primary-light lg:border-t-0 lg:bg-transparent lg:relative lg:block lg:flex lg:top-auto lg:p-0 lg:items-stretch lg:w-fit`}
        >
          <h1 className="text-lg font-medium capitalize lg:hidden">Menü</h1>
          <ul className="grid gap-y-4 mt-4 lg:flex lg:mt-0">
            <NavItem
              className={"lg:bg-transparent lg:h-full lg:px-8"}
              classNameIcon={"lg:hidden"}
              to={"/ueber-uns"}
              title={"Über uns"}
              icon={"building-user"}
              onClick={() => hideNavMenu()}
            />
            <NavItem
              className={"lg:bg-transparent lg:h-full lg:px-8"}
              classNameIcon={"lg:hidden"}
              to={"/geschaeftsfelder"}
              title={"Geschäftsfelder"}
              icon={"briefcase"}
              onClick={() => hideNavMenu()}
            />
            <NavItem
              className={"lg:bg-transparent lg:h-full lg:px-8"}
              classNameIcon={"lg:hidden"}
              to={"/karriere"}
              title={"Karriere"}
              icon={"graduation-cap"}
              onClick={() => hideNavMenu()}
            />
            <NavItem
              className={"lg:bg-transparent lg:h-full lg:px-8"}
              classNameIcon={"lg:hidden"}
              to={"/kontakt"}
              title={"Kontakt"}
              icon={"address-book"}
              onClick={() => hideNavMenu()}
            />
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
