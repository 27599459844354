import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Icon from "../ui/icon/Icon"

const NavItem = ({ className, classNameIcon, to, title, icon, onClick }) => {
  return (
    <li className="group">
      <AniLink paintDrip hex="#0d1627" to={to} title={title} onClick={onClick}>
        <div
          className={`ease-in duration-100 bg-brand-primary-light flex items-center p-6 rounded-sm text-white group-hover:bg-brand-secondary ${
            className ? className : ""
          }`}
        >
          <div className={`mr-4 ${classNameIcon}`}>
            <Icon icon={icon} size={"text-xl"} />
          </div>
          <h1 className="text-sm font-semibold capitalize">{title}</h1>
        </div>
      </AniLink>
    </li>
  )
}

export default NavItem
