import React from "react"
import Cookie from "../cookie/Cookie"
import Footer from "../footer/Footer"
import Header from "../header/Header"
import LogoAnimation from "./animations/LogoAnimation"

const Layout = ({ children }) => {
  return (
    <>
      <LogoAnimation />
      <Header />
      <main className="pt-header">{children}</main>
      <Cookie />
      <Footer />
    </>
  )
}

export default Layout
