import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import logo from "../../assets/images/logo.svg"

const Logo = () => {
  return (
    <div className="h-12 w-fit">
      <AniLink paintDrip hex="#0d1627" to={"/"}>
        <img alt="Logo | Avalum" className="h-full" src={logo} />
      </AniLink>
    </div>
  )
}

export default Logo
