import React from "react"
import SectionHeading from "../section/SectionHeading"
import SectionParagraph from "../section/SectionParagraph"
import Address from "./Address"
import Email from "./Email"
import Phone from "./Phone"

const ContactData = () => {
  return (
    <div className="grid gap-y-4">
      <SectionHeading>Kontakt</SectionHeading>
      <SectionParagraph>Wir freuen uns auf Ihren Kontakt!</SectionParagraph>
      <ul className="grid gap-y-2">
        <li>
          <Email />
        </li>
        <li>
          <Phone />
        </li>
        <li>
          <Address />
        </li>
      </ul>
    </div>
  )
}

export default ContactData
