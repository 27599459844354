import React from "react"
import Icon from "../ui/icon/Icon"

const Email = () => {
  return (
    <a
      href="mailto:info@avalum.de"
      title="info@avalum.de | Avalum Email"
      className="bg-brand-primary-light flex items-center gap-x-6 p-6 rounded-sm text-white group-hover:bg-brand-secondary"
    >
      <Icon icon="envelope" size={"text-xl"} />
      <div className="grid gap-y-1">
        <h1 className="text-sm font-semibold">Email</h1>
        <p className="text-sm">info@avalum.de</p>
      </div>
    </a>
  )
}

export default Email
