import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)
const SectionParagraph = ({ children }) => {
  const paragraphRef = useRef()
  useEffect(() => {
    gsap.to(paragraphRef.current, {
      scrollTrigger: {
        trigger: paragraphRef.current,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    })
  })
  return (
    <p
      className="text-sm font-medium stacking-wide tracking-wide leading-6 opacity-0 -translate-x-8 sm:text-base"
      ref={paragraphRef}
    >
      {children}
    </p>
  )
}

export default SectionParagraph
