import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import logo from "../../../assets/images/logo.svg"

const LogoAnimation = () => {
  const gsapTLRef = useRef(
    gsap.timeline({ defaults: { ease: "none", duration: 0.75 } })
  )
  const refLogo = useRef()
  const refLogoSection = useRef()
  useEffect(() => {
    var gsapTL = gsapTLRef.current
    const logo = refLogo.current
    const logoSection = refLogoSection.current
    gsapTL
      .to(logo, { y: 0, opacity: 1, duration: 0.75, delay: 0.25 })
      .to(logoSection, { opacity: "0", delay: 0.5 })
      .set(logoSection, { display: "none" })
  }, [refLogo, refLogoSection])
  return (
    <section
      className="bg-brand-primary fixed inset-0 flex h-screen items-center justify-center z-20"
      ref={refLogoSection}
    >
      <img
        alt="Logo | Avalum"
        className="w-40 opacity-0 -translate-y-8 lg:w-60"
        ref={refLogo}
        src={logo}
      />
    </section>
  )
}

export default LogoAnimation
