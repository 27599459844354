import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import logo from "../../assets/images/logo.svg"

const NavLogo = ({ onClick }) => {
  return (
    <li className="h-full">
      <AniLink paintDrip hex="#0d1627" to={"/"} onClick={onClick}>
        <img alt="Logo | Avalum" className="h-full" src={logo} />
      </AniLink>
    </li>
  )
}

export default NavLogo
