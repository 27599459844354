import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)
const SectionHeading = ({ children }) => {
  const headingRef = useRef()
  useEffect(() => {
    gsap.to(headingRef.current, {
      scrollTrigger: {
        trigger: headingRef.current,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      opacity: 1,
      x: 0,
      duration: 1,
    })
  })
  return (
    <h2
      className="uppercase font-semibold opacity-0 -translate-x-8 sm:text-lg md:text-xl"
      ref={headingRef}
    >
      {children}
    </h2>
  )
}

export default SectionHeading
