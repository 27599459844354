import React from "react"
import Icon from "../ui/icon/Icon"

const Phone = () => {
  return (
    <a
      href="tel:+49 1575 1900570"
      title="+49 1575 1900570 | Avalum Telefon"
      className="bg-brand-primary-light flex items-center gap-x-6 p-6 rounded-sm text-white group-hover:bg-brand-secondary"
    >
      <Icon icon="phone" size={"text-xl"} />
      <div className="grid gap-y-1">
        <h1 className="text-sm font-semibold">Telefon</h1>
        <p className="text-sm">+49 1575 1900570</p>
      </div>
    </a>
  )
}

export default Phone
