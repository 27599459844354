import React, { useEffect, useState, useRef } from "react"
import { useCookies } from "react-cookie"
import gsap from "gsap"

const Cookie = () => {
  const cookieWindowRef = useRef()
  const [cookies, setCookie] = useCookies(["isUserCookieNotified"])
  const [isUserCookieNotified, setIsUserCookieNotified] = useState(
    cookies.isUserCookieNotified
  )
  function setUserCookieNotified(value) {
    setIsUserCookieNotified(true)
    setCookie("isUserCookieNotified", value, {
      path: "/",
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    })
  }
  setTimeout(() => {
    setUserCookieNotified(true)
  }, 17000)

  useEffect(() => {
    const cookieWindow = cookieWindowRef.current
    if (isUserCookieNotified) {
      gsap.to(cookieWindow, { y: "100%" })
    }
  }, [isUserCookieNotified, cookieWindowRef])

  return (
    <div
      ref={cookieWindowRef}
      className="bg-brand-primary text-white fixed bottom-0 w-full"
      style={{ zIndex: 1 }}
    >
      <div className="max-w-content mx-auto">
        <div className="p-4 text-sm md:flex md:justify-between md:py-8">
          <div className="max-w-lg grid gap-y-2">
            <h3 className="text-base font-medium md:text-lg">Cookies</h3>
            <p>
              Wir verwenden nur nötige Cookies, um unser Geschäft und die
              Erfahrung, die Sie auf unserer Website haben, zu bewerten und zu
              verbessern.
            </p>
            <div>
              <span>Erfahren Sie mehr über Cookies in unserer </span>
              <a
                className="font-semibold"
                href={"/datenschutz"}
                title="Datenschutzerklärung"
                rel="noreferrer nofollow"
              >
                Datenschutzerklärung
              </a>
            </div>
          </div>
          <button
            className="mt-4 sm:mt-6 md:mt-8 border-b-2 border-brand-secondary px-2 pb-2 w-fit font-medium text-sm uppercase sm:text-base sm:px-4 md:px-6"
            onClick={() => setUserCookieNotified(true)}
          >
            Fenster schließen
          </button>
        </div>
      </div>
    </div>
  )
}

export default Cookie
