import React from "react"
import Icon from "../ui/icon/Icon"

const Address = () => {
  return (
    <a
      href="https://goo.gl/maps/TMmzPVzKMPXDs5eZA"
      target="_blank"
      title="Google Maps | Immobilien- und Projektmanagement Avalum GmbH"
      rel="nofollow noreferrer"
      className="bg-brand-primary-light flex items-center gap-x-6 p-6 rounded-sm text-white group-hover:bg-brand-secondary"
    >
      <Icon icon="location-dot" size={"text-xl"} />
      <div className="grid gap-y-1">
        <h1 className="text-sm font-semibold">Addresse</h1>
        <p className="text-sm">Montgolfier-Allee 24, 60486 Frankfurt am Main</p>
      </div>
    </a>
  )
}

export default Address
