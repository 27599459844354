import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NavItemSecondary = ({ to, title }) => {
  return (
    <li>
      <AniLink paintDrip hex="#0d1627" to={to} title={title}>
        <h1 className="text-sm font-medium capitalize">{title}</h1>
      </AniLink>
    </li>
  )
}

export default NavItemSecondary
